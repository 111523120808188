import Select from 'react-select'
import {doc, setDoc } from "firebase/firestore";
import db from "../../firebase";
import { UserAuth } from "../../context/AuthContext";
import { useContext } from "react";
import { DataContext } from "../../context/DataContext";

export function ManufacturerChooser() {

  const { user } = UserAuth();

  const setManufacturer = async (e) =>{
    const docRef = doc(db, "user_data", user.uid);
    const updateObj = {};
    updateObj[`homeManufacturer`] = e;
    await setDoc(docRef, updateObj, {merge:true});
  }
  
  const dataContext = useContext(DataContext);
  const userCustomData = dataContext.getCustomUserData();
  const manufacturerData = dataContext.getManufacturerData();
  
  const manufacturerOptions = ()=>{
    const all = [{value: "All Manufacturers", label: "All Manufacturers"}];
    return all.concat(Object.keys(manufacturerData).sort().map((k)=> ({value: manufacturerData[k], label: k}))).sort();
  }

  console.log(`user cus=tom data for Manufacture`, userCustomData?.data());

  const selectedManufacturer = () =>{
    if (userCustomData && userCustomData.data() && userCustomData.data().homeManufacturer){
      return userCustomData.data().homeManufacturer
    }
    return null;
  }

  return (
    <div className="w-72">
      <Select options={manufacturerOptions()} value={selectedManufacturer()} onChange={(e)=>setManufacturer(e)}></Select>
    </div>  
  );
}

export default ManufacturerChooser;
