import { Routes, Route } from "react-router-dom";
// import { Cog6ToothIcon } from "@heroicons/react/24/solid";
// import { IconButton } from "@material-tailwind/react";
import { Sidenav } from "widgets/layout/sidenav";
import { DashboardNavbar } from "widgets/layout/dashboard-navbar";
import { Configurator } from "widgets/layout/configurator";
import { Footer } from "widgets/layout/footer";


import routes from "routes/routes";
import { useMaterialTailwindController} from "context/LayoutContext";
// import { useMaterialTailwindController, setOpenConfigurator } from "context/LayoutContext";
import ListingDetails from "../pages/dashboard/ListingDetails";
import { UserAuth } from "../context/AuthContext";
// import { IconButton } from "@material-tailwind/react";
// import { Cog6ToothIcon } from "@heroicons/react/24/outline";
// import AGGridTest from "../pages/dashboard/AGGridTest";

export function Dashboard() {
  // const [controller, dispatch] = useMaterialTailwindController();
  const [controller] = useMaterialTailwindController();
  const { sidenavType } = controller;

  const { isAdmin } = UserAuth();
  const routesForRole = [{layout: "dashboard", pages:[]}];

  if (isAdmin){
    routesForRole[0].pages.push(...routes[0].pages)
  } else {
    routes[0].pages.forEach((p)=>{
      if (p.role !== 'admin'){
        routesForRole[0].pages.push(p)
      }
    })
  }

  return (
    <div className="bg-blue-gray-50/50">
      <Sidenav
        routes={routesForRole}
        brandImg={
          sidenavType ? "/small-airplane.png" : "/small-airplane.png"
        }
      />
      <div className="flex flex-col xl:ml-80 h-dvh mr-5">
        <DashboardNavbar />
        <Configurator />
        {/* <IconButton
          size="lg"
          color="white"
          className="fixed bottom-8 right-8 z-40 rounded-full shadow-blue-gray-900/10"
          ripple={false}
          onClick={() => setOpenConfigurator(dispatch, true)}
        >
          <Cog6ToothIcon className="h-5 w-5" />
        </IconButton> */}
        {/* <div className="bg-orange-200 h-[90%]">
          <AGGridTest></AGGridTest>
        </div> */}

      <div className="flex-1 overflow-auto">
        <Routes>
          {routesForRole.map(
            ({ layout, pages }) =>
              layout === "dashboard" &&
              pages.map(({ path, element }) => (
                <Route exact path={path} element={element} />
              ))
          )}
          <Route path="/listing-details/:slug" element={<ListingDetails></ListingDetails>}></Route>
          <Route path="/listing-aggregations/:slug" element={<ListingDetails></ListingDetails>}></Route>
        </Routes>
        </div>
        <div className="text-blue-gray-600">
          <Footer brandName={"PlanePortal"} />
        </div>
      </div>
    </div>
  );
}

Dashboard.displayName = "/src/layout/dashboard.jsx";

export default Dashboard;
