import Select from 'react-select'
import React, { useEffect, useState } from 'react';
import Papa from 'papaparse'; // Library for parsing CSV files
import { collection, writeBatch, doc, serverTimestamp } from 'firebase/firestore'; // Firestore functions
import db from '../../firebase';
import { useCollection } from 'react-firebase-hooks/firestore';
import { AgGridReact } from 'ag-grid-react';
import { Button, Card, CardBody, Input, Tab, TabPanel, Tabs, TabsBody, TabsHeader } from '@material-tailwind/react';
import { AppData} from '../../context/DataContext';
import { nanoid } from 'nanoid';

const autoSizeStrategy = {
  type: "fitGridWidth",
  defaultMinWidth: 20,
};

const ROW_MAP = {
  "Broker": {field:"broker", type: "string", include: true},
  "Date Sold": {field:"dt_sold", type: "date", include: true},
  // "Listing Date": {field:"dt_listing", type: "date", include: false},
  "Model": {field:"model", type: "string", include: true},
  "Original Listing Price": {field:"orig_price_usd", type: "number", include: true},
  "Registration #": {field:"reg", type: "string", include: true},
  "Sale Price": {field:"sale_price_usd", type: "number", include: true},
  "Serial #": {field:"serial_no", type: "string", include: true},
  "Total Flight time": {field:"total_time", type: "number", include: true},
  "Year": {field:"year", type: "number", include: true},
}

// check for any unmapped models or any
// invalide dates e.g. listing date / sold date in the future
const validateDataForUpload = (data) => {

  // TODO
  // check for duplicates

  data.forEach((d)=>{
    
    d.valid = true;
    if (d.pp_model === "UNKNOWN_MODEL"){
      d.valid = false;
    }

    if (!d.broker || !d.broker.trim().length){
      d.valid = false;
    }

    if (!d.sale_price_usd){
      d.valid = false;
    }

    if (!d.dt_listing){
      d.valid = false;
    }

  })
  return data;
}


const processModelUpdate = (newModel, modelData) => {
  if (modelData[newModel]){
    return newModel;
  } else {
    console.log(`could not find matchign model for ${newModel}`)
    return `UNKNOWN_MODEL`;
  }
}

// TODO - Notes
// allow edit of all fields 
// validate on upload / processUpload if the model is mapped and if all other minium requirements are met
// for now we just require year, broker, model, date of sale and sale price
// basic plan for now to highlight any rows that will not be uploaded based on the above.
// and show history of uploads
// create another tabs with a table to show history of uploans and data being used
const processUpload = (uploadData, modelData) => {

  const ret = []
  for(let i =0; i<uploadData.length;i++){

    const row = uploadData[i];
    const mapped ={};

    Object.keys(row).forEach((key)=>{
      if (!ROW_MAP[key]){
        return {
          sucess: false,
          msg: "Bad format."
        }
      }
      const newKey = ROW_MAP[key].field;
      let newVal = row[key].trim();

      if (ROW_MAP[key].type === `date`){
        // newVal = Timestamp.fromDate(new Date(newVal));
        newVal = new Date(newVal);
      } else if (ROW_MAP[key].type === `number`){
        newVal = parseFloat(newVal.replace(/[$,]/g, ''));
        newVal = parseInt(newVal);
      }
      mapped[newKey] = newVal;
      mapped[`id`] = nanoid();
    })

    let salesDataModel = mapped[`model`];

    // hacks
    salesDataModel = salesDataModel.replaceAll(`G1`,``).trim();
    // salesDataModel = salesDataModel.replaceAll(`G2+`,`G2`).trim();


    if (modelData[salesDataModel]){
      mapped[`pp_model`] = mapped['model'];
    } else {
      console.log(`couldnot find matchign model for ${salesDataModel}`)
      mapped[`pp_model`] = `UNKNOWN_MODEL`;
    }

    ret.push(mapped);
  }
  return ret;
}

const SalesDataIntegration = () => {

  const COL_DEFS = 
  [
      { 
        field: "valid", 
        cellDataType: "boolean",
        filterParams: { buttons: ["apply", "reset"]},
      },
      { 
        field: "year", 
        filter: "agNumberColumnFilter", 
        filterParams: { buttons: ["apply", "reset"]},
      },
      { 
        headerName:"Reg.", 
        field: "reg", 
        filter: 'agTextColumnFilter', 
        filterParams: { buttons: ["apply", "reset"]},
      },    
      { 
        headerName: "Model",
        field: "model", 
        filter: "agTextColumnFilter", 
        filterParams: { buttons: ["apply", "reset"]},
        headerTooltip: "The aircraft model",
        editable: true,
        onCellValueChanged: (e) => {
          console.log(`model change`, e, csvDataInvalid);
          const newValue = e.newValue;
          const mappedValue = processModelUpdate(newValue, modelData);
          console.log(`new value for model: ${newValue}, mapped value:`, mappedValue);        
          console.log(`csvdata invalid:`, csvDataInvalid);

          const updatedData = csvDataInvalid.map((r, index) =>{
            const ret = e.node.data.id === r.id ? { ...r, pp_model: mappedValue} : r;
            return ret;
          });

          console.log(`updated data`, updatedData);
          processChange(csvData.concat(updatedData));
        }
      },
      { 
        headerName: "PP Model",
        field: "pp_model", 
        filter: "agTextColumnFilter", 
        filterParams: { buttons: ["apply", "reset"]},
        headerTooltip: "The PlanePortal model this entry has been mapped to"
      },
      { 
        headerName: "Serial No.",
        field: "serial_no", 
        filter: "agTextColumnFilter", 
        filterParams: { buttons: ["apply", "reset"]},
      },
      { 
        headerName: "Broker",
        field: "broker", 
        filter: "agTextColumnFilter", 
        filterParams: { buttons: ["apply", "reset"]},
      },
      { 
        headerName: "Total Time",
        field: "total_time", 
        filter: "agTextColumnFilter", 
        filterParams: { buttons: ["apply", "reset"]},
      },
      { 
        headerName: "Orig. Price",
        field: "orig_price_usd", 
        filter: 'agNumberColumnFilter', 
        filterParams: { buttons: ["apply", "reset"]},
        valueFormatter: (params) => {
          // Format the value as currency
          return new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(params.value);
        },
      },
      { 
        headerName: "Sale Price",
        field: "sale_price_usd", 
        filter: 'agNumberColumnFilter', 
        filterParams: { buttons: ["apply", "reset"]},
        valueFormatter: (params) => {
          // Format the value as currency
          return new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(params.value);
        },
      },
      { 
        headerName: "Date Listed",
        field: "dt_listing", 
        filter: 'agDateColumnFilter', 
        filterParams: { buttons: ["apply", "reset"]},
      },
      { 
        headerName: "Date Sold",
        field: "dt_sold", 
        filter: 'agDateColumnFilter', 
        filterParams: { buttons: ["apply", "reset"]},
      },
    ];

  // console.log(`render SALES DATA....`);
  const [csvData, setCsvData] = useState([]);
  const [csvDataInvalid, setCsvDataInvalid] = useState([]);
  const [selectUploadDt, setSelectedUploadDt] = useState({label: "-", value: "-"});
  const [selectedSalesComps, setSelectedSalesComps] = useState([])

  const dataContext = AppData();
  const modelData = dataContext.getModelData();

  // console.log(`modelData`, modelData);

  const [snapshot, loading, error] = useCollection(collection(db, 'lma_sales_data'));

  const salesCompsPath = `lma_sales_data/${selectUploadDt.value}/sales_comps`;
  console.log(`sales comps path:`, salesCompsPath);
  const [snapshotComps, loadingComps, errorComps] = useCollection(collection(db, salesCompsPath));

  useEffect(()=>{

    if (snapshotComps && !loadingComps && !errorComps){

      const data = snapshotComps.docs.map((d)=> d.data());
      console.log( `sales comp data`, data);
      setSelectedSalesComps(data);

    }

  }, [snapshotComps, errorComps, loadingComps])

  if (loading) return <p>Loading...</p>;
  if (error) return <p>Error: {error.message}</p>;

  const ids = snapshot?.docs.map(doc => doc.id);

  const uploadDtOptions = ()=>{
    
    const options = ids.map((i) => {return { label: i, value: i}})
    // console.log("options",options);
    return options;
  }

  const process = (data) =>{
    const processed = processUpload(data, modelData);
    const validated = validateDataForUpload(processed);
    const valid = validated.filter((f) => f.valid);
    const invalid = validated.filter((f) => !f.valid);
    setCsvData(valid);
    setCsvDataInvalid(invalid)
  }

  const processChange = (data) =>{
    
    const validated = validateDataForUpload(data);
    const valid = validated.filter((f) => f.valid);
    const invalid = validated.filter((f) => !f.valid);
    setCsvData(valid);
    setCsvDataInvalid(invalid)
  }


  const handleFileUpload = (event) => {
    const file = event.target.files[0];
    Papa.parse(file, {
      header: true,
      skipEmptyLines: true,
      complete: function (results) {
         process(results.data);
      },
    });
  };

  const uploadToFirestore = async () => {
    if (csvData.length === 0) {
      alert('No data to upload');
      return;
    }
    
    const dtStr = (new Date()).toISOString().split(`T`)[0]

    console.log(`date str: ${dtStr}`);

    const collectionRef = collection(db, `lma_sales_data/${dtStr}/sales_comps`);
    await collectionRef.del;
    const batch = writeBatch(db);

    try {
            
      
      const ref = doc(db, `lma_sales_data/${dtStr}`);
      batch.set(ref, {
        created: serverTimestamp()
      });
      csvData.forEach((row, index) => {

        if (isNaN(row.dt_listing) || isNaN(row.dt_sold)){
          return;
        }

        const docRef = doc(collectionRef); // Automatically generates unique IDs
        // TODO hardcoded for now
        row.manufacturer = "cirrus"
        console.log(row);
        batch.set(docRef, row);
      });

      await batch.commit();
      alert('Data uploaded successfully');
    } catch (error) {
      console.error(error);
      alert('Error uploading data');
    }
  };

  const getRowStyle = (params) => {
    if (!params.data.valid) {
      return { background: "rgb(254 77 77)", opacity: 0.5 };
    }
  };

  const tabs = [
    {
      label: `Valid (${csvData.length})`,
      value: "valid",
      content: 
                      <div
                        className="ag-theme-quartz h-full mt-1 p-0"
                        style={{ height: '80%', width: '100%' }} // Inline style to fill parent container
                      >
                        <AgGridReact
                                  rowData={csvData}
                                  columnDefs={COL_DEFS}
                                  pagination={true}
                                  autoSizeStrategy={autoSizeStrategy}
                                  tooltipShowDelay={0}
                                  getRowStyle={getRowStyle}
                                  tooltipHideDelay={10000}
                                />
            </div>
               
    }, 
    {
      label: `Invalid (${csvDataInvalid.length})`,
      value: "invalid",
      content:  
                      <div
                        className="ag-theme-quartz h-full"
                        style={{ height: '80%', width: '100%' }} // Inline style to fill parent container
                      >
                        <AgGridReact
                                  rowData={csvDataInvalid}
                                  columnDefs={COL_DEFS}
                                  pagination={true}
                                  autoSizeStrategy={autoSizeStrategy}
                                  tooltipShowDelay={0}
                                  getRowStyle={getRowStyle}
                                  tooltipHideDelay={10000}
                                />
            </div>
                
    },
    {
      label: `Uploads`,
      value: "uploads",
      content:  
              <div className='flex flex-col h-full'>

                  <div className="bg-red-100 w-100" >
                  <Select options={uploadDtOptions()} value={selectUploadDt} onChange={(e)=>setSelectedUploadDt(e)}></Select>
                  </div>

                  <div
                        className="ag-theme-quartz h-full"
                        style={{ height: '80%', width: '100%' }} // Inline style to fill parent container
                      >
                        <AgGridReact
                                  rowData={selectedSalesComps}
                                  columnDefs={COL_DEFS}
                                  pagination={true}
                                  autoSizeStrategy={autoSizeStrategy}
                                  tooltipShowDelay={0}
                                  getRowStyle={getRowStyle}
                                  tooltipHideDelay={10000}
                                />
            </div>


              </div>

                
    }
  ];


  return (
  <div className='h-full'>
      <div className='flex flex-row gap-x-6'>
        <Card>
          <CardBody>
            <div className='flex gap-x-5'>
              <Input className='' type='file' accept='.csv' onChange={handleFileUpload} />    
              <Button onClick={uploadToFirestore} disabled={csvData.length === 0}>Upload</Button>
            </div>
          </CardBody>
        </Card>

        <Card>
          <CardBody>
            <div>
            <h1>Document IDs:</h1>
            <ul>
              {ids.map(id => (
                <li key={id}>{id}</li>
              ))}
            </ul>
            </div>
          </CardBody>
        </Card>

        {/* <Card>
          <CardBody>
            <div>
            <h1>Unmapped Models:</h1>
            <ul>
              {unknownModelCount(csvData)}
            </ul>
            </div>
          </CardBody>
        </Card>

        <Card>
          <CardBody>
            <div>
            <h1>Invalid:</h1>
            <ul>
              {invalidCount(csvData)}
            </ul>
            </div>
          </CardBody>
        </Card> */}
      </div>


      <Tabs value="valid" className="h-full mt-12">
          <TabsHeader className='p-0 mb-2'>
            {tabs.map(({ label, value }) => (
              <Tab key={value} value={value}>
                {label}
              </Tab>
            ))}
          </TabsHeader>
          <TabsBody className="h-full">
            {tabs.map(({ value, content }) => (
              <TabPanel key={value} value={value} className="h-full p-0">
                {content}
              </TabPanel>
            ))}
          </TabsBody>
        </Tabs>


   
    </div>
  
  );
};

export default SalesDataIntegration;
