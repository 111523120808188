import {
  query,
  doc
} from "firebase/firestore";
import { createContext, useContext} from "react";
import db from "../firebase";
import { useDocument } from "react-firebase-hooks/firestore";
import { UserAuth } from "./AuthContext";

export const UserDataContext = createContext();

const options = {
  snapshotListenOptions: { includeMetadataChanges: true },
};
const col = "user_data";

export function UserDataProvider({ children }) {

  const { user } = UserAuth();
  const customUserDataQuery = query(doc(db, `${col}/${user.uid}`));
  const [customUserData, loading, error] = useDocument(customUserDataQuery, options);
  const data = customUserData?.data() || {};

  return (
    <UserDataContext.Provider value={{userData: data, loading, error}}>
      {children}
    </UserDataContext.Provider>
  );
}

export const UserData = () => {
  return useContext(UserDataContext);
};
