import { createContext, useCallback, useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

import {
  signInWithEmailAndPassword,
  onAuthStateChanged,
  createUserWithEmailAndPassword,
  getAuth,
} from "firebase/auth";
import { doc, query, updateDoc } from "firebase/firestore";
import db from "../firebase";
import { useDocument } from "react-firebase-hooks/firestore";

export const UserContext = createContext();

export const AuthContextProvider = ({ children }) => {
  const auth = getAuth();
  const [user, setUser] = useState({});
  const [isAdmin, setIsAdmin] = useState(false);
  const [LMAData, setLMAData] = useState(false);

  const queryListing = query(doc(db, `tokenRefreshSignals/${user ? user.uid: "-"}`));
  const [refreshDoc, loading, error] = useDocument(user ? queryListing : null);

  const checkAndSetUserClaims = useCallback(()=>{
    if(auth.currentUser){
      auth.currentUser.getIdTokenResult()
        .then((idTokenResult)=>{
          
          if (idTokenResult.claims.admin){
            setIsAdmin(true);
          } else {
            setIsAdmin(false);
          }

          if (idTokenResult.claims.LMAData){
            setLMAData(true);
          } else {
            setLMAData(false);
          }
        })
    } else {
      setIsAdmin(false);
    }
  },[auth])


  useEffect(()=>{
    const checkRefresh = async ()=>{
      if (refreshDoc && !loading && !error){
        const data = refreshDoc.data();
        if(data && data.refreshRequired){
          try {
            await auth.currentUser.getIdToken(true)
            checkAndSetUserClaims();
            const docRef = doc(db, "tokenRefreshSignals", auth.currentUser.uid );
            await updateDoc(docRef, {
              refreshRequired: false
            });

          } catch(error){
            console.error(`Error refreshing token:`, error);
          }
        }
      }
    }
    checkRefresh();
  }, [refreshDoc, loading, error, auth.currentUser,checkAndSetUserClaims])

  const navigate = useNavigate();

  const createUser = (email, password) => {
    return createUserWithEmailAndPassword(auth, email, password);
  };

  const logOut = async () => {
    await auth.signOut();
    navigate("/");
    console.log("You are logged out");
  };

  const logIn = async (email, password) => {
    console.log("logging in");
    await signInWithEmailAndPassword(auth, email, password);
    navigate("/dashboard/home");
  };

  const checkLoginAndRedirect = ()=>{
    if (auth.currentUser){
      navigate("/dashboard/home");
    }
  }

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, (currentuser) => {
      setUser(currentuser);
      checkAndSetUserClaims();
    });

    return () => {
      unsubscribe();
    };
  }, [auth, checkAndSetUserClaims]);

  return (
    <UserContext.Provider value={{ createUser, user, logOut, logIn, isAdmin, LMAData, checkLoginAndRedirect }}>
      {children}
    </UserContext.Provider>
  );
};

export const UserAuth = () => {
  return useContext(UserContext);
};
