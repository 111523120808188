import React, { useState, useEffect } from "react";
import "react-datepicker/dist/react-datepicker.css";
import { Button, IconButton, Input, Tab, TabPanel, Tabs, TabsBody, TabsHeader, Typography } from "@material-tailwind/react";
import { LockClosedIcon, PencilSquareIcon } from "@heroicons/react/24/outline";
import CardChart from "../../widgets/cards/card-chart";
import MarketOverview from "./market-overview";
import ListingsView from "./ListingsView";
import { useMarketReportData } from "../../context/MarketReportContext";
import PriceHoursView from "./PriceHoursView";
import SalesDataView from "./SalesDataView";
import { UserData } from "../../context/UserContext";
import { UserAuth } from "../../context/AuthContext";
import { useMaterialTailwindController } from "../../context/LayoutContext";
import Loading from "../../widgets/layout/loading";

export function MarketReportView({data, analysisData}) {

  const { userData } = UserData();
  const { user } = UserAuth();
  const [controller] = useMaterialTailwindController();
  const { showLMASalesData } = controller;

  const [title, setTitle] = useState(`Market Report: ${data.name}`);
  const [editing, setEditing] = useState(false);
  const [seriesAvgPx, setSeriesAvgPx] = useState([]);
  const [seriesNoListings, setSeriesNoListings] = useState([]);
  const [seriesDOM, setSeriesDOM] = useState([]);
  const [seriesPR, setSeriesPR] = useState([]);
  // const [seriesSales, setSalesData] = useState([{data:[]}])

  const [rawSalesData, setRawSalesData] = useState([])
  const { fetchData, loading, error } = useMarketReportData();

  const token = user.stsTokenManager.accessToken;

  useEffect(()=>{
    setTitle(data.name);
  }, [data])


  const [lmaSalesDataEnabled, setLMASalesDataEnabled] = useState(false);

  useEffect(() => {
    const getData = async () => {

      const specs = {...data, ...userData.excludeListings, token};

      const fetchedData = await fetchData(specs); // Request data for the specific chartId

      // console.log(`mkt report view data`, fetchedData);
      if (!fetchedData)return;
      const dts = fetchedData.results;
   
      setRawSalesData(fetchedData.salesData);
      setLMASalesDataEnabled(fetchedData.userHasLMAData)

      const datAvgPx = Object.keys(dts).map((d)=> {return {x: new Date(d).getTime(), y: dts[d].avg}})
      const datNoListings = Object.keys(dts).map((d)=> {return {x: new Date(d).getTime(), y: dts[d].noL}})
      const datDOM = Object.keys(dts).map((d)=> {return {x: new Date(d).getTime(), y: dts[d].dom}})
      const datPR = Object.keys(dts).map((d)=> {return {x: new Date(d).getTime(), y: dts[d].pr * 100}})
      let datSales = Object.keys(dts).map((d)=> { 
        if (dts[d].sl){
          return {x: new Date(d).getTime(), y: dts[d].sl}
        }
        return null;
      })

      datSales = datSales.filter((d)=> d !== null)

      // const salesDataPoints = fetchedData.avgSalesData.map((p) => {return {x : p.dt_sold, y : p.sale_price_usd }})

      const seriesAvgPx = [
        {
          name: "Avg. Price",
          data: datAvgPx
        },
      ];

      if (showLMASalesData){
        seriesAvgPx.push({
          name: "Sales Prices",
          data: datSales
        });
      }

      const seriesNoListings = [
        {
          name: "No. Listings",
          data: datNoListings
        }
      ];

      const seriesNDOMs = [
        {
          name: "DOM",
          data: datDOM
        }
      ];

      const seriesPR  = [
        {
          name: "Average Listing Price Change",
          data: datPR
        }
      ];

      // const seriesSalesData  = [
      //   {
      //     name: "Sale Price",
      //     data: datSales
      //   }
      // ];

      setSeriesAvgPx(seriesAvgPx);
      setSeriesNoListings(seriesNoListings);
      setSeriesDOM(seriesNDOMs);
      setSeriesPR(seriesPR);
      // setSalesData(seriesSalesData);
    };

    getData();

  }, [data, userData, token, fetchData, showLMASalesData]); // Re-run when chartId changes or when user_data i.e. exclusions change

  if (loading) {
    return (
      <Loading text={`Creating report for ${data.name}`}></Loading>
    );
  } 
  if (error) return <p>Error: {error}</p>;

  const forSaleChartData = {
    title: `For sale (${data.period})`,
    series: seriesNoListings
  }

  const listingPriceChartData = {
    title: `Average price (${data.period})`,
    series: seriesAvgPx
  }

  const daysOnMarketChartData = {
    title: `Days on Market (${data.period})`,
    series: seriesDOM
  }

  const priceChangeChartData = {
    title: `Average Listing Price Change % (${data.period})`,
    series: seriesPR
  }

  // const salesChartData = {
  //   title: `Sales (${data.period})`,
  //   series: seriesSales
  // }

  const handleEditing = () => {
    setEditing(true);
  }

  const stopEditing = (e) => {
    if (e.key === "Enter"){
      setEditing(false);
    }
  }

  const tabs = [
    {
      label: "Overview",
      value: "overview",
      disabled: false,
      content:  <div className="grid gap-y-6 gap-x-6 md:grid-cols-2 xl:grid-cols-2">
                  <MarketOverview data={data} analysisData={analysisData}></MarketOverview>
                  <CardChart data={listingPriceChartData}>
                
                  </CardChart>
                </div>
    },
    {
      label: "Trends",
      value: "trends",
      disabled: false,
      content:  
            <div className=" h-[100%]">
              <div className="grid gap-y-6 gap-x-6 md:grid-cols-2 xl:grid-cols-2 pb-12">
                  <CardChart data={forSaleChartData}></CardChart>
                  <CardChart data={listingPriceChartData}></CardChart>
                  <CardChart data={daysOnMarketChartData}></CardChart>
                  <CardChart data={priceChangeChartData}></CardChart>
                  {/* <CardChart data={salesChartData}></CardChart> */}
                </div>
            </div>
    },
    {
      label: "Listings",
      value: "listings",
      disabled: false,
      content: <div className="h-[95%]">

              <ListingsView 
                  dupes={analysisData.ignoredDups} 
                  models={data.models} 
                  manufacturer={data.manufacturers[0].value.ids} 
                  title={data.name} 
                  period={data.period} 
                  year={data.year}></ListingsView>

      </div>
    },
    {
      label: "Aircraft Price vs. Total Time",
      value: "price-vs-total-time",
      disabled: false,
      content: <PriceHoursView data={data} userData={userData}></PriceHoursView>
    },
    {
      label: "Sales Data",
      value: "sales-data",
      icon: lmaSalesDataEnabled ? null : LockClosedIcon,
      disabled: lmaSalesDataEnabled ? false : true,
      content: <SalesDataView data={rawSalesData} period={data.period}></SalesDataView>
    },
  ];

  return (
    <div className="h-full mr-3">
      <div className="h-[8%] bg-blue-300 rounded-md shadow-md">
      <div class="flex items-center justify-between p-4">
    <div className="text-gray-700 font-semibold">

    <div className="">
          <IconButton
            size="sm"
            variant="text"
            color="white"
            onClick={() => setEditing(true)}>
            <PencilSquareIcon className="w-5 h-5"/>
          </IconButton> 
        </div>
    </div>


    <div className="text-gray-500 text-center flex-grow">
    <div className="flex justify-center" onDoubleClick={() => handleEditing()}>
            {!editing && 
              <div className="flex flex-row">
                <Typography style={{ userSelect: "none"}} variant="h4" color="white">
                  {title}
                </Typography>
              </div>
            }
            { editing && 
              <div className="flex flex-row w-96 gap-3">
                <Input variant="outlined" onChange={(e) => setTitle(e.target.value)} onKeyDown={(e) => stopEditing(e)} defaultValue={`${title}`}></Input>
                <Button size="sm" variant="small" color="white" onClick={(e) => setEditing(false)}>Save</Button>
              </div>
            }
        </div>
    </div>


    <div className="text-gray-700 font-semibold">
            <div></div>
    </div>
  </div>
      </div>
        <Tabs value="overview" className="h-[92%] mt-5">
          <TabsHeader>
            {tabs.map(({ label, value, disabled, icon }) => (
              <Tab key={value} value={value} disabled={disabled}>
                <div className="flex items-center gap-2">
                  {icon && React.createElement(icon, { className: "w-5 h-5" })}
                  {label}
                </div>
              </Tab>
            ))}
          </TabsHeader>
          <TabsBody className="h-full">
            {tabs.map(({ value, content }) => (
              <TabPanel key={value} value={value} className="h-full overflow-auto">
                {content}
              </TabPanel>
            ))}
          </TabsBody>
        </Tabs>
    </div>
  );
}

export default MarketReportView;
