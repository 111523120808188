import { Option, Select } from "@material-tailwind/react";
import MarketReportView from "./MarketReportView";
import { useChartData, useDashboardPanelData, useSelectedPanel, useSelectPanel } from "../../context/DashboardContext";
import Loading from "../../widgets/layout/loading";

export function MarketReportPage() {

  // const { dashPanelData, dashPanelsLoading, dashPanelsError } = useDashboardPanelData();
  const { dashPanelData, loadingDashPanelData } = useDashboardPanelData();

  const selectedpanelId = useSelectedPanel();
  const selectPanel = useSelectPanel();
  const selectedData = dashPanelData.filter((p)=> p.panelId === selectedpanelId)[0];

  // const { analysisData, loading, error } = useChartData(selectedpanelId);
  const { analysisData, loading } = useChartData(selectedpanelId);

  if (loading || loadingDashPanelData){
    return <Loading text={`Loading market reports`}></Loading>
  }

  return (
    <div className="h-[87%]">
      <div className="w-72 mt-2">
      <Select
        label="Select Report"
        value={selectedpanelId}
        onChange={(val) => selectPanel(val)}
      >
        {dashPanelData.map((p)=>{
          return <Option key={p.panelId} value={p.panelId}>{p.name}</Option>
        })}
      </Select>
    </div>
      <div className="mt-12 h-full">
          {selectedData && analysisData && <MarketReportView data={selectedData} analysisData={analysisData}/>}
      </div>
      
    </div>
  );
}

export default MarketReportPage;
