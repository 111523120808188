import {
  collection, orderBy, query, limit,
} from 'firebase/firestore';
import { useCollection } from 'react-firebase-hooks/firestore';
import Loading from "../widgets/layout/loading";
import './ScrapesSummary.css';
import { formatSource, friendlyTimeFormat } from '../utils';
import db from '../firebase';
import React, { useEffect, useState } from 'react';
import { Typography } from '@material-tailwind/react';
import { getUniqueListingIdsForScrape } from '../fb-functions';

const options = {
  snapshotListenOptions: { includeMetadataChanges: true },
};
const qController = query(
  collection(db, `controller_listings/placeholder_all/scrapes_v1`),
  orderBy('started', 'desc'),
  limit(5),
);

const qPlanecheck = query(
  collection(db, `planecheck_listings/placeholder_all/scrapes_v1`),
  orderBy('started', 'desc'),
  limit(5),
);

const scrapedListingCount = (dat) => {
  if (!dat) return 0;
  if (dat.totalCounter) return dat.totalCounter;
  if (!dat.totalListingCountBreakdown) return 0;
  const sum = Object.values(dat.totalListingCountBreakdown).reduce((a, b) => a + b, 0);
  return sum;
};

const percentageProgress = (dat) => {
  const sum = scrapedListingCount(dat);
  return Math.round(sum / dat.initialListingCount * 100);
};

  // const totalTimeTaken = (doc) => {
  //   if (!doc.data().updated || !doc.data().started){
  //     return "N/A";
  //   } 
  //   const diff = doc.data().updated.toDate() - doc.data().started.toDate();
  //   return (diff /1000/60).toFixed(1) + " min";
  // };


function ScrapesSummary() {

  const [valueC, loadingC, errorC] = useCollection(qController, options);
  const [valueP, loadingP, errorP] = useCollection(qPlanecheck, options);
  const [value, setValue] = useState([]);
  const [uniqueScrapesValue, setUniqueScrapesValue] = useState([]);

  useEffect(()=>{
    
    const getUniqueListingData = async () => {
      // console.log(`running getUniqueListingData`)
      const newUniqueScrapesValue = {};
      const proms = [];
      for(let i =0;i<value.length;i++){
        const data = value[i];
        proms.push(getUniqueListingIdsForScrape({
          source: data.source,
          scrapeId: data.id
        }));
      }
      const res = await Promise.all(proms);

      res.forEach((r)=>{
        newUniqueScrapesValue[r.data.scrapeId] = r.data.size;
        // console.log(r.data.scrapeId, r.data.size)
      });
      setUniqueScrapesValue(newUniqueScrapesValue)

    }
    if (value){
      getUniqueListingData()      
    }
  }, [value])

  useEffect(()=>{

    if (!loadingC && !loadingP){
      if (valueC && valueP){
        const pData = valueP.docs.map((d)=> {return {id: d.id, source: "planecheck", ...d.data()}})
        const cData = valueC.docs.map((d)=> {return {id: d.id, source: "controller", ...d.data()}})
        let allData = pData.concat(cData);
        allData = allData.sort((a, b) => b.started.toDate() - a.started.toDate())
        setValue(allData)
      }
    }

  }, [valueC, valueP, loadingC, loadingP])


  if (loadingC || loadingP) return (

    <Loading></Loading>
  )

  return (
      <div className='h-full'>
        {(errorP || errorC) && <strong>Error:{JSON.stringify(errorP)}:{JSON.stringify(errorC)}</strong>}
        {value.map(
              (d, key) => (
                <div key={d.id} className="grid grid-cols-12 items-center justify-center gap-5 py-2">
                  
                  <div className='col-span-2'>
                    <Typography
                      variant="small"
                      color="blue-gray"
                      className="block font-medium"
                    >
                    {formatSource(d.source)}
                    </Typography>
                  </div>
                  <div className='col-span-4'>
                    <Typography
                      variant="small"
                      color="blue-gray"
                      className="block font-medium"
                    >
                    {friendlyTimeFormat(d.started)}
                    </Typography>
                    <Typography
                      variant="small"
                      className="text-xs font-medium text-blue-gray-500"
                    >
                      {d.id}  
                    </Typography>
                  </div>
                  <div className='col-span-2'>
                    <Typography
                      variant="small"
                      color="blue-gray"
                      className="block font-medium"
                    >
                    {percentageProgress(d)}%
                    
                    </Typography>
                    
                  </div>
                  <div className='col-span-2'>
                    <Typography
                      variant="small"
                      color="blue-gray"
                      className="block font-medium"
                    >
                    {scrapedListingCount(d)}
                    
                    </Typography>
                    
                  </div>
                  <div className='col-span-2'>
                    <Typography
                      variant="small"
                      color="blue-gray"
                      className="block font-medium"
                    >
                    {(uniqueScrapesValue  && uniqueScrapesValue[d.id]) ? uniqueScrapesValue[d.id] : "loading..."}
                    
                    </Typography>
                    
                  </div>
                </div>
              )
            )}
      </div>
  );
}

export default ScrapesSummary;
