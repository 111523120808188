import { Spinner } from "@material-tailwind/react";

export function Loading({ text }) {

  return (
    <div className="flex items-center justify-center h-full bg-gray-100">
      <div className="flex flex-col p-6 bg-white rounded-lg shadow-lg text-center justify-center items-center">
        <h1 className="text-lg font-semibold">{text}</h1>
        <Spinner></Spinner>
      </div>
    </div>);

}

Loading.defaultProps = {
  brandName: "",
};

Loading.displayName = "/src/widgets/layout/loading.jsx";

export default Loading;
