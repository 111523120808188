import React, { useCallback, useState } from "react";
import db from "../../firebase";

import {
  where,
  query,
  getCountFromServer,
  collection,
} from "firebase/firestore";

import { Button, Typography } from "@material-tailwind/react";

async function getUnknownTotalTimeCount(source){
  const ref = collection(db, "listing_aggregations_v1");
  let q = query(ref, where("timeState.totalTime", "==", -1), where("source", "==", source));
  return await getCountFromServer(q);
}

async function getTotalTimeCount(source){
  const ref = collection(db, "listing_aggregations_v1");
  let q = query(ref, where("source", "==", source), where("timeState.totalTime", ">=", 0));
  return await getCountFromServer(q);
}

async function getTotalCount(source){
  const ref = collection(db, "listing_aggregations_v1");
  let q = query(ref, where("source", "==", source));
  return await getCountFromServer(q);
}

async function getTotalListingAggregationCount(){
  const ref = collection(db, "listing_aggregations_v1");
  let q = query(ref);
  return await getCountFromServer(q);
}
async function getTotalListingAggregationCountWithYear(){
  const ref = collection(db, "listing_aggregations_v1");
  let q = query(ref, where("year", "!=", "_YEAR_"));
  return await getCountFromServer(q);
}

export default function MiscCleaning() {

  const [unknownPlanecheckTotalTimeCount, setPlanecheckUnknownTotalTimeCount] = useState(0);
  const [unknownControllerTotalTimeCount, setControllerUnknownTotalTimeCount] = useState(0);
  
  const [planecheckTotalTimeCount, setPlanecheckTotalTimeCount] = useState(0);
  const [controllerTotalTimeCount, setControllerTotalTimeCount] = useState(0);
  
  const [totalPlanecheckCount, setTotalPlanecheckCount] = useState(0);
  const [totalControllerCount, setTotalControllerCount] = useState(0);

  const [totalListingAggregationCount, setTotalListingAggregationCount] = useState(0);
  const [totalListingAggregationCountWithYear, setTotalListingAggregationCountWithYear] = useState(0);


  const refresh = useCallback(()=>{
    getUnknownTotalTimeCount("planecheck").then((snapshot)=>{
      setPlanecheckUnknownTotalTimeCount(snapshot.data().count);
    })
    getUnknownTotalTimeCount("controller").then((snapshot)=>{
      setControllerUnknownTotalTimeCount(snapshot.data().count);
    })
    getTotalCount("planecheck").then((snapshot)=>{
      setTotalPlanecheckCount(snapshot.data().count);
    })
    getTotalCount("controller").then((snapshot)=>{
      setTotalControllerCount(snapshot.data().count);
    })
    getTotalTimeCount("controller").then((snapshot)=>{
      setControllerTotalTimeCount(snapshot.data().count);
    })
    getTotalTimeCount("planecheck").then((snapshot)=>{
      setPlanecheckTotalTimeCount(snapshot.data().count);
    })
    getTotalListingAggregationCount().then((snapshot)=>{
      setTotalListingAggregationCount(snapshot.data().count);
    })
    getTotalListingAggregationCountWithYear().then((snapshot)=>{
      setTotalListingAggregationCountWithYear(snapshot.data().count);
    })
  }, [])

  return (
    <>
     <div className="mt-12 border-solid border-2 border-indigo-600" >
      Total Listing Aggregations: {totalListingAggregationCount}
     </div>
      <div className="mt-12 border-solid border-2 border-indigo-600">
        <div className="border-solid border-2 border-indigo-600">
          Planecheck TotalTime stats
        </div>
        <div>
          <Typography>
            Total Planecheck: {totalPlanecheckCount}
          </Typography>
          <Typography>
            Unknown TotalTime Count (-1): {unknownPlanecheckTotalTimeCount}
          </Typography>
          <Typography>
            TotalTime Count (!= -1): {planecheckTotalTimeCount}
          </Typography>
          <Typography>
            Sum: {planecheckTotalTimeCount + unknownPlanecheckTotalTimeCount}
          </Typography>
        </div>
      </div>
      <div className="mt-12 border-solid border-2 border-indigo-600">
      <div className="border-solid border-2 border-indigo-600">
          Controller TotalTime stats
        </div>
        <div>
          <Typography>
            Total Controller: {totalControllerCount}
          </Typography>
          <Typography>
            Unknown Controller TotalTime Count (-1): {unknownControllerTotalTimeCount}
          </Typography>
          <Typography>
            Controller TotalTime Count (!= -1): {controllerTotalTimeCount}
          </Typography>
          <Typography>
            Sum: {controllerTotalTimeCount + unknownControllerTotalTimeCount}
          </Typography>
        </div>
      </div>
      <div className="mt-12">
        <div>
          <Typography>
            Total Year Count {totalListingAggregationCountWithYear}
          </Typography>
        
        </div>
      </div>
      <div>
        <Button onClick={()=> refresh()}>Refresh</Button>
      </div>
    </>
  );
}
