import DashboardCard from "../../widgets/cards/dashboard-card";
import DashboardCardPlaceholder from "../../widgets/cards/dashboard-card-placeholder";
import { useDashboardPanelData } from "../../context/DashboardContext";
import Loading from "../../widgets/layout/loading";

export function DashboardPage() {
  const { dashPanelData, loadingDashPanelData } = useDashboardPanelData();
  // const { dashPanelData, loading, error } = useDashboardPanelData();

  if (loadingDashPanelData){
    return <Loading text={"Loading dashboard..."}></Loading>
  }

  if (!loadingDashPanelData && !dashPanelData.length){
    return <DashboardCardPlaceholder />
  }

  const dashboardItemsLayout = () =>{
   
    const elements = [];
    for (let i = 0;i<dashPanelData.length;i++){
      const d = dashPanelData[i];
      elements.push(<DashboardCard key={`db-card${d.panelId}`} data={d}/> );
      // elements.push(<DashboardCardChart key={`db-card-chart${d.panelId}`} data={d} />);
    }

    elements.push(<DashboardCardPlaceholder key={`db-card-placeholder`} />)
    return elements
  }

  return (
    <div className="h-[100%] overflow-auto">
      <div className="mb-12 mt-12 grid gap-y-12 gap-x-6 md:grid-cols-2 xl:grid-cols-2">
        {dashboardItemsLayout()}
      </div>
    </div>
  );
}

export default DashboardPage;
