import { AgGridReact } from "ag-grid-react";
import { useState } from "react";
import CardChart from "../../widgets/cards/card-chart";

const autoSizeStrategy = {
    type: "fitGridWidth",
    defaultMinWidth: 50,
    columnLimits: [
      {
        colId: "country",
        minWidth: 900,
      },
    ],
};

export function SalesDataView({data, period}) {

  let chartSeries = Object.values(data).map((d)=> {return {x: new Date(d.dt_sold).getTime(), y:d.sale_price_usd }})

  chartSeries = chartSeries.sort((a, b) => a.x - b.x);

  const seriesSalesData  = [
    {
      name: "Sale History",
      data: chartSeries
    }
  ];

  // console.log(`sales history`, chartSeries)

  // Column Definitions: Defines the columns to be displayed.
  const [colDefs] = useState([
    { field: "dt_sold" },
    { field: "year", filter: "agTextColumnFilter" },
    { field: "reg", filter: "agNumberColumnFilter" },
    { field: "sale_price_usd" },
    { field: "model" },
  ]);

  const priceChangeChartData = {
    title: `Sales History (${period})`,
    series: seriesSalesData
  }

  return (
    <div className="h-full">
  
            <div>
              <CardChart data={priceChangeChartData}></CardChart>
            </div>

            <div
              className="ag-theme-quartz h-full" // applying the Data Grid theme
              style={{ height: "95%" }} // the Data Grid will fill the size of the parent container
            >
              <AgGridReact
                rowData={data}
                columnDefs={colDefs}
                pagination={true}
                autoSizeStrategy={autoSizeStrategy}
                tooltipShowDelay={0}
                tooltipHideDelay={10000}
              />
            </div>
      </div>
  
  );
}

export default SalesDataView;
