import {
  and,
  collection,
  query,
  where,
  doc,
  arrayUnion,
  arrayRemove,
  Timestamp,
  setDoc,
} from "firebase/firestore";
import React, { useState, useContext, useEffect, useMemo} from "react";
import db from "../../firebase";
import { connectFunctionsEmulator, getFunctions } from "firebase/functions";

import {
  friendlyModelName,
  periodToDate,
  friendlyManufacturerName,
  setColumnVisibility,
  friendlyDealerName,
  formatSource,
} from "../../utils";
import { useCollection } from "react-firebase-hooks/firestore";

import "react-datepicker/dist/react-datepicker.css";
import { DataContext } from "../../context/DataContext";
import { UserAuth } from "../../context/AuthContext";
import { AgGridReact } from "ag-grid-react";
import { Button } from "@material-tailwind/react";
import ListingDetails from "./ListingDetails";
import { ListingViewContext } from "../../context/ListingViewContext";


const autoSizeStrategy = {
    type: "fitGridWidth",
    defaultMinWidth: 50,
    columnLimits: [
      {
        colId: "country",
        minWidth: 900,
      },
    ],
};
const functions = getFunctions();

if (window.location.href.indexOf("localhost") > -1) {
  connectFunctionsEmulator(functions, "127.0.0.1", 5001);
}

export function ListingsView({
  models,
  manufacturer,
  period,
  year,
  dupes,
}) {
  const { user } = UserAuth();
  // const navigate = useNavigate();

  const dataContext = useContext(DataContext);
  // const [page, setPage] = useState(1);
  // const [listingsCount, setListingCount] = useState();
  // const [sortField, setSortField] = useState("first_seen");
  // const [sortDirection, setSortDirection] = useState("asc");
  const defaultPeriod = period ? period : "3m";
  const [selectedPeriod] = useState(defaultPeriod);

  const userCustomData = dataContext.getCustomUserData();
  const options = {
    snapshotListenOptions: { includeMetadataChanges: true },
  };

  const COLLECTION = "listing_aggregations_v1";

  let modelIds = [];
  let allModelsSeen = false;

  for (let i = 0; i < models.length; i++) {
    if (models[i].label !== "All Models") {
      modelIds = modelIds.concat(models[i].value.ids);
    } else {
      allModelsSeen = true;
    }
  }
  if (allModelsSeen) modelIds = [];

  let years = useMemo(()=>{
    let ret = [];
    for (let i = 0; i < year.length; i++) {
      ret = ret.concat(year[i].value);
    }
    ret = ret.filter((y) => y !== "All Years");
    return ret;
  }, [year]);

  const periodDate = periodToDate(selectedPeriod);

  const wheres = [];
  wheres.push(where("manufacturer", "in", manufacturer));
  wheres.push(where("last_seen", ">=", Timestamp.fromDate(periodDate)));


  if (modelIds.length) {
    wheres.push(where("model", "in", modelIds));
  }

  if (years.length) {
    // wheres.push(where("year", "in", years));
  }

  const queryNewListingsData = query(
    collection(db, COLLECTION),
    and(...wheres),
    // limit(2000)
  );

  const [listingsValue] = useCollection(queryNewListingsData, options);
  // console.log(`LISTINGS VALUE (ListingsView)`, listingsValue.docs)

  // const onChangeSelectedPeriod = (e) => {
  //   // console.log(`changing selected period`, e);
  //   setSelectedPeriod(e);
  // };

  // Row Data: The data to be displayed.
  const [rowData, setRowData] = useState([]);
  const [gridApi, setGridApi] = useState(null);

  const gridOptions = {
    onGridReady: (params) => {
      setGridApi(params.api);
    }
  };
  

  const cellClicked = (e) => {
    // console.log(`cell clicked`, e)
    // navigate(`/dashboard/listing-details/${e.data.uniqueListingId}`)
    setSelectedListings(e.data.uniqueListingId);
  };

  const excludeClicked = async (e) => {
    const uniqueListingId = e.data.uniqueListingId;
    const docRef = doc(db, "user_data", user.uid);

    const updateObj = {
      excludeListings: !e.newValue
        ? arrayRemove(uniqueListingId)
        : arrayUnion(uniqueListingId),
    };
    await setDoc(docRef, updateObj, { merge: true });
  };

  const listingViewContext = useContext(ListingViewContext);
  // const cds = useMemo(()=> [...listingViewContext.subsetDefs(["source", "noPhotos", "dealer"])], [listingViewContext]);
  const cds = useMemo(()=>[...listingViewContext.colDefs], [listingViewContext]);

  cds[0].onCellClicked = cellClicked;
  cds.push({
    headerName: "Exclude",
    field: "exclude",
    editable: true,
    cellRenderer: "agCheckboxCellRenderer",
    cellEditor: "agCheckboxCellEditor",
    filterParams: { buttons: ["apply", "reset"] },
    onCellValueChanged: excludeClicked,
    headerTooltip:
      "Whether to exclude this lisitng from the dashboard analysis calculations",
  });
  cds.push({
    headerName: "Duplicate",
    field: "duplicate",
    filterParams: { buttons: ["apply", "reset"] },
    headerTooltip:
      "Duplicate = true means this listing is not used in dashboard analysis",
  });

  // Column Definitions: Defines the columns to be displayed.
  const [colDefs] = useState(cds);

  useEffect(() => {
    if (listingsValue) {
      // don't filter listings wheen "All Years" is used
      const filtered = years.length ? listingsValue.docs.filter((f)=> years.includes(f.data().year)) :listingsValue.docs;
      const newData = filtered.map((l) => {
        return {
          model: friendlyModelName(dataContext, l.data().model),
          year: l.data().year,
          manufacturer: friendlyManufacturerName(
            dataContext,
            l.data().manufacturer
          ),
          price: {
            price: l.data().price,
            currency: l.data().currency,
            price_usd: l.data().price_usd,
          },
          total_time: l.data().timeState.totalTime,
          price_change_usd_abs: l.data().price_change_usd_abs,
          price_change_pct_abs_usd: l.data().price_change_pct_abs_usd,
          first_seen: l.data().first_seen,
          last_seen: l.data().last_seen,
          days_active: l.data().days_active,
          dealer: friendlyDealerName(dataContext, l.data().dealerName), 
          noPhotos: l.data().np, 
          source: formatSource(l.data().source),
          duplicate: dupes.includes(l.data().uniqueListingId),
          reg: l.data().reg,
          ls: l.data().ls,
          aps: l.data().aps,
          noChanges: l.data().no_price_changes,
          uniqueListingId: l.data().uniqueListingId,
          exclude:
            userCustomData &&
            userCustomData.data() &&
            userCustomData.data().excludeListings &&
            userCustomData
              .data()
              .excludeListings.includes(l.data().uniqueListingId),
        };
      });
      setRowData(newData);
      setColumnVisibility(gridApi, userCustomData, cds, "listingViewMktReportColSettings");
    }
  }, [listingsValue, dataContext, userCustomData, dupes, cds, gridApi, years]);

  const rowStyle = { background: "" };

  const getRowStyle = (params) => {
    if (
      userCustomData &&
      userCustomData.data() &&
      userCustomData.data().excludeListings &&
      userCustomData
        .data()
        .excludeListings.includes(params.data.uniqueListingId)
    ) {
      return { background: "rgb(255 235 238)", opacity: 0.5 };
    }

    if (dupes && dupes.includes(params.data.uniqueListingId)) {
      return { background: "rgb(211 211 211)", opacity: 0.5 };
    }
  };

  const [selectedListing, setSelectedListings] = useState();

  return (
    <div className="h-full">
      {selectedListing && (
        <div className="mt-12">
          <Button onClick={(e) => setSelectedListings(null)}>BACK</Button>
        </div>
      )}
      <div
        className="mt-1 h-full"
        style={{ display: !selectedListing ? "block" : "none" }}
      >
          {/* <div className="mt-12 mb-8 flex flex-col gap-12">
            <PeriodSelector selectedPeriod={selectedPeriod} setSelectedPeriod={onChangeSelectedPeriod}></PeriodSelector>
          </div> */}

          {/* <div className="mt-12 mb-8 flex flex-col gap-12">
            <h3>{title}</h3>
          </div> */}

            <div
              className="ag-theme-quartz h-full" // applying the Data Grid theme
              style={{ height: "100%" }} // the Data Grid will fill the size of the parent container
            >
              <AgGridReact
                rowData={rowData}
                columnDefs={colDefs}
                pagination={true}
                autoSizeStrategy={autoSizeStrategy}
                rowStyle={rowStyle}
                getRowStyle={getRowStyle}
                tooltipShowDelay={0}
                tooltipHideDelay={10000}
                gridOptions={gridOptions}
              />
            </div>
      </div>
      {selectedListing && (
        <div className="mt-12">
          <ListingDetails ulid={selectedListing}></ListingDetails>
        </div>
      )}
    </div>
  );
}

export default ListingsView;
