import {
  collection, orderBy, query, limit,
  where,
} from 'firebase/firestore';
import { useCollection } from 'react-firebase-hooks/firestore';
import Loading from "../widgets/layout/loading";
import './ScrapesSummary.css';
import { formatListingTitle, formatPercentage, formatPrice, friendlyDealerName, friendlyTimeFormat, periodToDate } from '../utils';
import db from '../firebase';
import React, { useContext, useEffect, useState } from 'react';
import { Typography } from '@material-tailwind/react';
import { DataContext } from '../context/DataContext';
import { useNavigate } from 'react-router-dom';
import { ArrowDownIcon, ArrowUpIcon, XMarkIcon } from '@heroicons/react/24/outline';

let options = {
  snapshotListenOptions: { includeMetadataChanges: true },
}

const m6 = periodToDate("6m")

function PriceChangeSummary() {

  const dataContext = useContext(DataContext);
  const userCustomData = dataContext.getCustomUserData();

  const mostViewedAircraftPC = userCustomData?.data() && 
    userCustomData.data().homeManufacturer.value.ids ?

   query(
    collection(db, "listing_aggregations_v1"), 
    where("manufacturer", "in", userCustomData.data().homeManufacturer.value.ids),
    where("last_change_dt", "!=", -1), 
    orderBy("last_change_dt", "desc"), 
    limit(10)) :
    
    query(
      collection(db, "listing_aggregations_v1"), 
      where("last_change_dt", "!=", -1), 
      orderBy("last_change_dt", "desc"), 
      limit(10))

  const [value, loading, error] = useCollection(mostViewedAircraftPC, options);
  const [mostViewedAircraft, setMostViewedAircraft] = useState([]) 

  
  useEffect(()=>{
  if (!loading){
      let data = value?.docs.map((d)=> d.data());
      data = data?.filter((d)=> d.scrapeTs.toDate() > m6)
      setMostViewedAircraft(data);
    }
  },[value, loading]);

  // console.log(mostViewedAircraft)
  const navigate = useNavigate();

  const handleListingClicked = (uniqueListingId) =>{
    navigate(`/dashboard/listing-details/${uniqueListingId}`)
  }

  if (loading) return (

    <Loading></Loading>
  )

  return (
      <div className='h-full'>
        {(error) && <strong>Error:{JSON.stringify(error)}}</strong>}
        {mostViewedAircraft?.map(
              (m, index) => (
                <div key={`pricechange${index}`} className="grid grid-cols-12 justify-center gap-5 py-2">
                  
                  <div className='col-span-3'>
                    <Typography
                            as="span"
                            variant="small"
                            className="text-xs font-medium text-blue-gray-500"
                          >
                            {friendlyTimeFormat(m.last_change_dt)}
                          </Typography>
                  </div>

                  <div className='col-span-2'>
                    <Typography
                        variant="small"
                        className="flex gap-1 font-normal text-blue-gray-600"
                      >
                        {m.price_change_usd > 0 && 
                        <>
                        <ArrowUpIcon
                          strokeWidth={3}
                          className={`h-3.5 w-3.5 text-green-500`}
                        />
                        <strong>{formatPercentage(m.price_change_pct_usd)}</strong> </>
                        }
                        {m.price_change_usd < 0 && 
                        <>
                        <ArrowDownIcon
                          strokeWidth={3}
                          className={`h-3.5 w-3.5 text-red-500`}
                        />
                        <strong>{formatPercentage(m.price_change_pct_usd)}</strong> </>
                        }
                        {(m.price_change_usd === 0 || isNaN(m.price_change_usd) )&& 
                          <XMarkIcon
                            strokeWidth={3}
                            className={`h-3.5 w-3.5 ${m.price_change_usd > 0 ? "text-green-500" : "text-red-500" }`}
                          />
                        }
                      </Typography>
                  </div>
                  <div className='col-span-6'>
                    <div onClick={() => handleListingClicked(m.uniqueListingId)}>
                      <Typography
                        variant="small"
                        color="blue-gray"
                        className="block font-medium"
                      >
                        {formatListingTitle(m, dataContext)} 
                      </Typography>
                      <Typography
                        as="span"
                        variant="small"
                        className="text-xs font-medium text-blue-gray-500"
                      >
                        {formatPrice(m.price,m.currency)}
                      </Typography>
                    </div> 
                    <div onClick={() => handleListingClicked(m.uniqueListingId)}>
                      <Typography
                        variant="small"
                        color="blue-gray"
                      >
                        {friendlyDealerName(dataContext, m.dealerName)} 
                      </Typography>
                    </div> 
                  </div>
                  <div className='col-span-3'>
                    
                  </div>
                </div>

              )
            )}
      </div>
  );
}

export default PriceChangeSummary;
