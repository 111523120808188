import React from "react";
import {
  Card,
  CardBody,
  CardHeader,
  Dialog,
  DialogBody,
  IconButton,
  Typography,
} from "@material-tailwind/react";
import { formatFirebaseTimestamp, formatPrice } from "../../utils";
import { CheckIcon, XCircleIcon} from "@heroicons/react/24/outline";

const PriceHistoryDialog = ({open, handleOpen, listingScrapesData, setOpen }) => {

  return (
    <Dialog size="sm" open={open} handler={handleOpen}>

      <DialogBody>
      
      <Card>
          <CardHeader
            floated={false}
            shadow={false}
            color="transparent"
            className="m-0 flex items-center justify-between p-6"
          >
            <div>
              <Typography variant="h6" color="blue-gray" className="mb-1">
                Price History
              </Typography>
              <Typography
                variant="small"
                className="flex items-center gap-1 font-normal text-blue-gray-600"
              >
                <CheckIcon strokeWidth={3} className="h-4 w-4 text-blue-500" />
                <strong>
                  {listingScrapesData && listingScrapesData.docs.length}
                </strong>
                scrapes
              </Typography>

            <IconButton
              size="sm"
              variant="text"
              onClick={(e) => setOpen(false)}
              className="!absolute top-2 right-4 rounded-full w-5 h-5 ">
              <XCircleIcon className="w-5 h-5" />
            </IconButton> 
            </div>
          </CardHeader>
          <CardBody className="flex items-stretch">

            <div className="overflow-y-auto max-h-96" >
              <table className="">
                <thead>
                  <tr>
                    {["date", "price", "id"].map((el) => (
                      <th
                        key={el}
                        className="border-b border-blue-gray-50 py-3 px-4 text-left"
                      >
                        <Typography
                          variant="small"
                          className="text-[11px] font-medium uppercase text-blue-gray-400"
                        >
                          {el}
                        </Typography>
                      </th>
                    ))}
                  </tr>
                </thead>
                <tbody>
                  {listingScrapesData &&
                    listingScrapesData.docs.map((doc, key) => {
                      const className = `py-2 px-4 ${
                        key === listingScrapesData.docs.length - 1
                          ? ""
                          : "border-b border-blue-gray-50"
                      }`;

                      return (
                        <tr key={key}>
                          <td className={className}>
                            <div className="flex items-center gap-4">
                              <Typography
                                variant="small"
                                color="blue-gray"
                                className="font-bold"
                              >
                                {formatFirebaseTimestamp(doc.data().scrapeTs)}
                              </Typography>
                            </div>
                          </td>
                          <td className={className}>
                            <Typography
                              variant="small"
                              className="text-xs font-medium text-blue-gray-600"
                            >
                              {formatPrice(doc.data().price, doc.data().currency)}
                            </Typography>
                          </td>
                          <td className={className}>
                            <Typography
                              variant="small"
                              className="text-xs font-medium text-blue-gray-600"
                            >
                              {doc.id}
                            </Typography>
                          </td>
                        </tr>
                      );
                    })}
                </tbody>
              </table>
            </div>
          </CardBody>
        </Card>

      </DialogBody>
     
    </Dialog>
  );
};

export default PriceHistoryDialog;
