import React, { useState, useEffect, useMemo, useCallback } from "react";
import Chart from "react-apexcharts";
import {
  connectFunctionsEmulator,
  getFunctions,
  httpsCallable,
} from "firebase/functions";

import { useWindowSize } from "@uidotdev/usehooks";
import "react-datepicker/dist/react-datepicker.css";
import { Spinner } from "@material-tailwind/react";
import createTrend from "trendline";
// import { PencilSquareIcon } from "@heroicons/react/24/outline";

const functions = getFunctions();
const func = httpsCallable(functions, "getPriceHoursData");
if (window.location.href.indexOf('localhost') > -1) {
  connectFunctionsEmulator(functions, "127.0.0.1", 5001)
}

const defaultData = [];

function getNewAnnotations(annotations, newAnnotation){
  const newAnnotations = [];
  let removed = false;
  for(let i =0;i<annotations.length;i++){
    const a = annotations[i];
    if (a.x === newAnnotation.x){
      removed = true;
    } else {
      newAnnotations.push(a);
    }
  }
  if (!removed){
    newAnnotation.label['offsetY'] =  (newAnnotations.length + 1) * 30;
    newAnnotations.push(newAnnotation);
  } 
  return newAnnotations;
}

export function PriceHoursView({data, userData}) {

  const size = useWindowSize();
  const [isLoading, setIsLoading] = useState(true)
  const [priceHoursData, setPriceHoursData] = useState(defaultData)
  // const [title, setTitle] = useState(`Price vs. Hours: ${data.name}`);
  // const [editing, setEditing] = useState(false);
  const [annotations, setAnnotations] = useState([]);

  const getPriceHoursData = useCallback(async ()=> {
    try {
      setIsLoading(true);
      console.log(`getting PRICE _______ HOURS DATA for panel id ${data.panelId}`, data)
      // data.userId = user.uid;

      const response = await func({...data, userData});
      setPriceHoursData(response.data.series)
      console.log(response.data)
    } catch(error){
    } finally {
      setIsLoading(false);
    }
  }, [data, userData])


  const trend = createTrend(priceHoursData ? priceHoursData: [], 'x', 'y');
  const trendData = priceHoursData.map((phd) => { return {x:phd.x, y: trend.calcY(phd.x)}});
  const chartWidth = size.width/1.5;

  const getChart = useMemo(()=>{
    const chartConfigDefault = {
      type: "line",
      
      width: chartWidth,
      height: chartWidth * 0.666,
      options: {
        
        chart: {
          toolbar: {
            show: true,
          },
          events: {
            click: function (event, chartContext, config) {
              console.log(`click event detected`, config.globals)

                // Get chart dimensions and position
          const chartElement = chartContext.el; // The DOM element of the chart
          const chartRect = chartElement.getBoundingClientRect();

          const chartWidth = chartRect.width;
          const chartHeight = chartRect.height;

          // Calculate relative click position
          const relativeX = event.clientX - chartRect.left;
          const relativeY = event.clientY - chartRect.top;

          console.log("Relative Click X:", relativeX);
          console.log("Relative Click Y:", relativeY);
          console.log("Chart Width:", chartWidth);
          console.log("Chart Height:", chartHeight);

          // Ensure the click is within the chart area
          if (relativeX >= 0 && relativeX <= chartWidth && relativeY >= 0 && relativeY <= chartHeight) {
            console.log("Click is inside the chart!");
          } else {
            console.log("Click is outside the chart area.");
          }

              if (config.dataPointIndex >= 0) {
                const x = config.globals.seriesX[config.seriesIndex][config.dataPointIndex];

                // console.log(`X: ${x}`)
                // const y = config.globals.seriesY[config.seriesIndex][config.dataPointIndex];
                // console.log(`Y: ${y}`)

                console.log(chartConfig['series'][0].data[config.dataPointIndex].r)

                setAnnotations(getNewAnnotations(annotations, 
                  {
                    x,
                    strokeDashArray: 1,
                    offsetX: 0,
                    offsetY: 0,
                    opacity: 0.3,
                    borderColor: "#775DD0",
                    label: {
                      borderColor: "#775DD0",
                      textAnchor: (relativeX / chartWidth) > 0.9 ? 'end' : 'start',
                      orientation: 'horizontal',
                      style: {
                        color: "#fff",
                        background: "#775DD0",
                        fontSize: '20px',
                        fontWeight: 400,
                      },
                      text: chartConfig['series'][0].data[config.dataPointIndex].r
                    }
                  })
                );
              }
            },
          },
        },
        markers: {
          size: [6, 0],
        },
        title: {
          show: "Price vs. Hours",
        },
        tooltip: {
          custom: function({ series, seriesIndex, dataPointIndex, w }) {
            // Only return the value and omit the series name
            return '<div class="tooltip-custom" style="padding: 3px">' +
                     '<span><b>' +  priceHoursData[dataPointIndex].r  + `</b> $ ${Math.round(series[seriesIndex][dataPointIndex])} k </span>` +
                   '</div>';
          },
          enabled: true,
          x: {
            show: true
          },
          y: {
            formatter: function (val, { dataPointIndex, seriesIndex}) {
              if (seriesIndex === 0){
                return priceHoursData[dataPointIndex].r + ` $${val} k` // Custom formatting of the Y-axis value
              } else {
                return null;
              }
            }
          },
        },
        dataLabels: {
          enabled: false,
        },
        annotations: {
          xaxis: annotations, // Dynamic annotations for arrows and labels
        },
        colors: ["#d40f04", "#40a832"],
        legend: {
          show: false,
        },
        xaxis: {
          type: 'numeric',
          tickAmount: 5
        },
        yaxis: {
          type: 'numeric',
          tickAmount: 10,
          labels: {
            formatter: function (value) {
              return value.toFixed(0); // Rounds the value to 2 decimal places
            }
          }
        }
      },
    };
  
    const chartConfig = {...chartConfigDefault};
    chartConfig['series'] = [{
      name: 'Points',
      type: 'scatter',
      data: priceHoursData
    }, {
      name: 'Trend',
      type: 'line',
      data: trendData
    }];
    return chartConfig;
  }, [priceHoursData, chartWidth, trendData, annotations])
  

  useEffect(()=>{
    console.log(`loading...`)
    getPriceHoursData()
  }, [data, getPriceHoursData])

  // const handleEditing = () => {
  //   setEditing(true);
  // }

  // const stopEditing = (e) => {
  //   if (e.key === "Enter"){
  //     // setTitle(e.target.value);
  //     setEditing(false);
  //   }
  // }

  if(isLoading){
    return <div className="items-center"><Spinner></Spinner></div>
  }

  return (
    <div>
    {/* <div>
      <IconButton
                  size="sm"
                  variant="text"
                  onClick={() => setEditing(true)}>
                <PencilSquareIcon className="w-5 h-5"/>
              </IconButton> 
    </div> */}
        {/* <div className="flex justify-center" onDoubleClick={() => handleEditing()}>
          {!editing && 
            <div className="flex flex-row">
              <h3 style={{ userSelect: "none"}}>{title}</h3>
            </div>
          }
          { editing && 
            <div className="flex flex-row w-96 gap-3">
              <Input variant="outlined" onChange={(e) => setTitle(e.target.value)} onKeyDown={(e) => stopEditing(e)} defaultValue={`${title}`}></Input>
              <Button size="sm" variant="small" onClick={(e) => setEditing(false)}>Save</Button>
            </div>
          }
        </div> */}
        <div className="flex flex-col items-center">
          <Chart {...getChart} />
        </div>
    </div>
  );
}

export default PriceHoursView;
