import React, { useCallback, useContext, useEffect, useState } from "react";
import {
  Card,
  CardBody,
  Input,
  CardHeader,
  Button,
  Typography,
} from "@material-tailwind/react";

import db from "../../firebase";

import {
  doc,
  limit,
  where,
  query,
  updateDoc,
  collectionGroup,
  getDocs,
  increment,
  serverTimestamp,
  getCountFromServer
} from "firebase/firestore";
import { DataContext } from "../../context/DataContext";

async function getUnformattedModelCount(){
  const ref = collectionGroup(db, "model_data");
  let q = query(ref, where("formatted", "==", "SERVER_NEW"));
  return await getCountFromServer(q);
}

async function getTotalListingAggregationCount(){
  const ref = collectionGroup(db, "listing_aggregations_v1");
  let q = query(ref);
  return await getCountFromServer(q);
}

async function getListingsAggregationWithModel(){
  const ref = collectionGroup(db, "listing_aggregations_v1");
  let q = query(ref, where("model", "!=", "_NO_MODEL_SEMAPHORE_"));
  return await getCountFromServer(q);
}

async function getData(dataName, selectedManufacturer, hideFormatted) {
  const ref = collectionGroup(db, dataName);
  const query_limit = 400;
  const constraints = [limit(query_limit)];
  if (selectedManufacturer.label !== 'All Manufacturers'){
    constraints.push(where('manufacturer', "array-contains-any", selectedManufacturer.value.ids));
  }
  if (hideFormatted){
    constraints.push(where("formatted", "==", "SERVER_NEW"));
  }

  // console.log('constraints', constraints)

  let q = query(ref, ...constraints);
  return await getDocs(q);
}

function modelObjectFromSnapshot(snapshot){
  const modelsObj = {};
  for (let i = 0; i < snapshot.docs.length; i++) {
    const doc = snapshot.docs[i];
    const id = doc.id;
    const source = doc.data().source;
    const key = `${source}_${id}`;
    modelsObj[key] = doc.data();
    modelsObj[key].id = id;
  }
  return modelsObj;
}

export default function ModelCleaning({hideFormatted}) {
  
  const [noModelListingAggregationCount, setNoModelListingAggregationCount] = useState(0);
  const [totalListingAggregationCount, setTotalListingAggregationCount] = useState(0);

  const [models, setModels] = useState({});
  const [edits, setEdits] = useState({});
  const [unformattedModelCount, setUnformattedModelCount] = useState(0);

  const dataContext = useContext(DataContext);
  const selectedManufacturer = dataContext.getConstraintState().selectedManufacturer;

  const refresh = async () =>{

    getListingsAggregationWithModel().then((snapshot)=>{
      setNoModelListingAggregationCount(snapshot.data().count);
    })
    getTotalListingAggregationCount().then((snapshot)=>{
      setTotalListingAggregationCount(snapshot.data().count);
    })

  };

  useEffect(()=>{
    getUnformattedModelCount().then((snapshot)=>{
      setUnformattedModelCount(snapshot.data().count);
    })
  },[models])

  useEffect(() => {
    getData("model_data", selectedManufacturer, hideFormatted).then((snapshot)=>{
      setModels(modelObjectFromSnapshot(snapshot));
    })
  }, [selectedManufacturer, hideFormatted]);

  useEffect(() => {
    refresh();
  }, []);

  const handleUpdate = useCallback(async (key) => {
    // console.log(models[key].editedName);

    try {
      if (!edits[key]){
        return;
      }

      let source = models[key].source;
      if (Array.isArray(models[key].source)){
        throw new Error("unexpected array passed for source");
      }

      const docRef = doc(db, "models", source, "model_data", models[key].id);

      const trimmedEdit = edits[key]; 

      await updateDoc(docRef, {
        formattedName: trimmedEdit,
        formatted: increment(1),
        formattedDt: serverTimestamp()
      });

      console.log("Document written with ID: ", docRef.id);
      
      getData("model_data", selectedManufacturer, hideFormatted).then((snapshot)=>{
        setModels(modelObjectFromSnapshot(snapshot));
        console.log('got data')
      })

    } catch (e) {
      console.error("Error adding document: ", e);
    }


  },[edits, models, selectedManufacturer, hideFormatted]);

  const handleChange = (key, e) => {
    console.log(e.target.value);
    const newData = { ...edits };
    newData[key] = e.target.value;
    setEdits(newData);
  };

  const rows = Object.keys(models)
    .sort()
    .map((key, index) => {
      const className = `py-3 px-5 ${
        index === Object.keys(models).length - 1
          ? ""
          : "border-b border-blue-gray-50"
      }`;

      return (
        <tr key={key}>
          <td className={className}>
             <Typography
              className="text-xs font-medium text-blue-gray-600"
            >
              {index+1}
            </Typography>
          </td>
          {/* <td className={className}>
            {models[key] && <Typography
              className="text-xs font-medium text-blue-gray-600"
            >
              {models[key].listingId.length}
            </Typography>
          }
          </td> */}
          <td className={className}>
            <Typography
              className="text-xs font-medium text-blue-gray-600"
            >
              {models[key].id}
            </Typography>
          </td>
          <td className={className}>
            {models[key].formattedName &&<Typography
              className="text-xs font-medium text-blue-gray-600"
            >
              {models[key].formattedName}
            </Typography>}
          </td>
          <td className={className}>
            <Typography
              className="text-xs font-medium text-blue-gray-600"
            >
              {models[key].source}
            </Typography>
          </td>
          <td className={className}>
            <Typography
              className="text-xs font-medium text-blue-gray-600"
            >
              {models[key].manufacturer}
            </Typography>
          </td>
          <td className={className}>
            <Input
              onFocus={(e)=>handleChange(key, e)}
              className="text-xs font-medium text-blue-gray-600"
              onChange={(e) => handleChange(key, e)}
              // value={edits[key]}
              defaultValue={models[key].formattedName ? models[key].formattedName : models[key].id}
            ></Input>
          </td>
          <td className={className}>
            <Button onClick={(e) => handleUpdate(key)}>
              Update
            </Button>
          </td>
        </tr>
      );
    });

  return (
    <div className="mt-12">
            <div className="flex flex-col">
              <Typography> Listing Aggregations with Model:{noModelListingAggregationCount} </Typography>
              <Typography> Total Listing Aggregations:{totalListingAggregationCount}</Typography>
              <Button className="w-48" onClick={()=> refresh()}>Refresh</Button>
            </div>
      <div className="mt-12 mb-8 flex flex-col gap-12">
        <Card>
          <CardHeader variant="gradient" color="blue" className="mb-8 p-6">
            Model Name Mapping (identically named manufacturers show once) Unformatted: {unformattedModelCount}
          </CardHeader>
          <CardBody>
            <table className="w-full min-w-max table-auto text-left">
              <thead>
                <tr>
                  {[
                    "Index",
                    // "Count",
                    "Unformatted",
                    "Formatted Name",
                    "Source",
                    "Manufacturer",
                    "Edited Name",
                    "",
                  ].map((el) => (
                    <th
                      key={el}
                      className="border-b border-blue-gray-50 py-3 px-5 text-left"
                    >
                      <Typography
                        variant="small"
                        className="text-[11px] font-bold uppercase text-blue-gray-400"
                      >
                        {el}
                      </Typography>
                    </th>
                  ))}
                </tr>
              </thead>
              <tbody>{rows}</tbody>
            </table>
          </CardBody>
        </Card>
      </div>
    </div>
  );
}
